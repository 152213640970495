import { action, makeObservable, observable } from 'mobx'
import { createContext } from 'react'
import UserStore from './UserStore'

class Stores {
  isLoading = false

  constructor() {
    this.userStore = new UserStore(this)
    makeObservable(this, {
      isLoading: observable,
      setIsLoading: action.bound
    })

    this.isLoading = false
  }

  setIsLoading(status) {
    this.isLoading = status
  }
}

export default createContext(new Stores())