import styled from "styled-components";
import NeedMaster from "layout/NeedMaster";
import { useEffect, useState } from "react";
import { authReqeust } from "utils";
import axios from "axios";
import { Link, useParams, useHistory } from "react-router-dom";
import { Label } from "comp/Input";
import { Button } from "comp/Button";
import DataTable from "comp/DataTable";

const Style = styled.div`
  h2,
  h3 {
    margin: 48px 0 24px;
  }
  .action {
    margin-bottom: 24px;
    button {
      margin-right: 8px;
    }
  }
  .info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  .contracts,
  .agreements {
    margin-top: 24px;
    .list {
      margin-top: 24px;
      overflow: auto;
    }
  }
`;

const UserDetail = () => {
  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState({});
  const [agreements, setAgreements] = useState([]);
  const [contracts, setContracts] = useState([]);

  const fetchUser = async () => {
    const result = await authReqeust(axios.get, `/users/${params.id}`);
    setData(result.data);
  };

  const resetUserPassword = async () => {
    if (confirm("초기화 하시겠습니까?")) {
      try {
        await authReqeust(
          axios.patch,
          `/users/reset-password/${params.id}`,
          {}
        );
        alert("초기화 성공");
      } catch (e) {
        alert("초기화 실패");
      }
    }
  };

  const deleteUser = async () => {
    if (confirm("삭제 하시겠습니까?")) {
      try {
        await authReqeust(axios.delete, `/users/${params.id}`);
        alert("삭제 성공");
        history.push("/admin");
      } catch (e) {
        alert("삭제 실패");
      }
    }
  };

  const fetchContracts = async () => {
    const result = await authReqeust(
      axios.get,
      `/daily-contracts/user/${params.id}`
    );
    setContracts(result.data);
  };

  const deleteContract = async (id) => {
    await authReqeust(axios.delete, `/daily-contracts/${id}`);
    fetchContracts();
  };

  const fetchAgreements = async () => {
    const result = await authReqeust(
      axios.get,
      `/parent-agreements/user/${params.id}`
    );
    setAgreements(result.data);
  };

  const deleteAgreement = async (id) => {
    await authReqeust(axios.delete, `/parent-agreements/${id}`);
    fetchContracts();
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <NeedMaster>
      <Style>
        <h2>회원 상세 정보</h2>
        <div className="action">
          <Link to={`/admin/users/${params.id}/edit`}>
            <Button secondary size="small">
              회원정보 수정
            </Button>
          </Link>
          <Button secondary size="small" onClick={resetUserPassword}>
            비밀번호 초기화
          </Button>
          <Button secondary size="small" onClick={deleteUser}>
            회원 삭제
          </Button>
        </div>
        <div className="info">
          {Object.keys(data).map((key) => (
            <Label key={key} label={key}>
              {data[key].toString()}
            </Label>
          ))}
        </div>
        <div className="contracts">
          <h3>일일근로 계약서</h3>
          <div className="action">
            <Button onClick={fetchContracts}>불러오기</Button>
          </div>
          <div className="list">
            <DataTable
              tableName="contracts"
              datas={contracts}
              onDeleteClick={(id) => deleteContract(id)}
              headers={[
                "id",
                "work_address",
                "work_position",
                "work_start_at",
                "work_end_at",
                "created_at",
              ]}
            />
          </div>
        </div>
        <div className="agreements">
          <h3>부모님 동의서</h3>
          <div className="action">
            <Button onClick={fetchAgreements}>불러오기</Button>
          </div>
          <div className="list">
            <DataTable
              tableName="agreements"
              datas={agreements}
              onDeleteClick={(id) => deleteAgreement(id)}
              headers={["id", "created_at"]}
            />
          </div>
        </div>
      </Style>
    </NeedMaster>
  );
};

export default UserDetail;
