import styled from "styled-components";
import theme from 'styles/theme'

export const Division = styled.div`
  height: ${({ height }) => height || '16px'};
  width: 100%;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.nd(0.08)};
`