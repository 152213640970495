import theme from 'styles/theme'
import { useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

const Style = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${theme.nd(0.1)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20000;
  &.active {
    animation: fadeIn 0.2s forwards;
  }
  &.inactive {
    animation: fadeOut 0.2s forwards;
  }
  .bar {
    width: 40px;
    height: 4px;
    background-color: ${theme.nl(1)};
    border-radius: 4px;
    animation: spin 1s infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(1080deg);
    }
    100% {
      transform: rotate(1080deg);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    99% {
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
`

const LoadingLayer = () => {
  const store = useStore()

  return <Style className={store.isLoading ? 'active' : 'inactive'}>
    <div className="bar"></div>
  </Style>
}

export default observer(LoadingLayer)
