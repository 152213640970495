import axios from "axios"
import { action, makeObservable, observable } from "mobx"
import { destroyToken, getToken, setToken } from "utils";

const userScheme = {
  id: 0,
  name: '',
  phone: '',
  email: '',
  address1: '',
  address2: '',
  post_code: '',
  social_number1: '',
  social_number2: '',
  bank_account: '',
  bank_name: '',
  bank_username: '',
  uuid: '',
  is_master: false,
}

class UserStore {
  currentUser = null;
  loginStatus = false;
  root = null

  constructor(root) {
    makeObservable(this, {
      currentUser: observable,
      loginStatus: observable,
      setCurrentUser: action.bound,
      login: action.bound,
      logout: action.bound
    })
    this.root = root
    this.currentUser = { ...userScheme };
    this.loginStatus = false;
  }

  get isLogin() {
    return this.loginStatus;
  }

  async getAuth() {
    const token = getToken()
    if (token) {
      const result = await axios.get('/auth', {
        headers: {
          access_token: token
        }
      })
      this.setCurrentUser(result.data.user);
      this.loginStatus = true;
      setToken(result.data.access_token);
    }
  }

  async login(params) {
    try {
      const result = await axios.post('/auth/login', params);
      const { user, access_token } = result.data;
      setToken(access_token);
      this.setCurrentUser(user);
      this.loginStatus = true;
      return result
    } catch(e) {
      throw e
    }
  }

  logout() {
    this.setCurrentUser({ ...userScheme });
    this.loginStatus = false;
    destroyToken();
  }

  setCurrentUser(user) {
    this.currentUser = user;
  }

  initUserStore() {
    this.currentUser = { ...userScheme };
    this.loginStatus = false;
  }
}

export default UserStore;
