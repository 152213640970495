import styled from 'styled-components'
import { Button } from 'comp/Button'
import { useHistory } from 'react-router-dom'
import theme from 'styles/theme'
import { useStore } from 'hooks'
import { observer } from 'mobx-react-lite'

const ForbiddenStyle = styled.div`
  text-align: center;
  padding-top: 128px;
  h2 {
    font-weight: 400;
    padding-bottom: 32px;
  }
`

const Style = styled.div`
  .tabs {
    display: flex;
    padding: 8px;
    .tab-item {
      margin: 8px;
      padding: 8px 16px;
      background-color: ${theme.nd(0.04)};
      color: ${theme.nd(0.6)};
      font-size: 14px;
    }
  }
`

const NeedMaster = ({ children }) => {
  const history = useHistory();
  const { userStore } = useStore()
  
  if (!userStore.currentUser.is_master) return (
    <ForbiddenStyle>
      <h2>관리자 권한이 필요한 페이지입니다.</h2>
      <Button onClick={() => history.push('/login')}>로그인</Button>
    </ForbiddenStyle>
  )

  return (
    <Style>
      { children }
    </Style>
  )
}

export default observer(NeedMaster)
