import styled from "styled-components";
import NeedMaster from "layout/NeedMaster";
import { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "utils";
import DataTable from "comp/DataTable";

const Style = styled.div`
  margin-top: 80px;
  .list {
    margin-top: 48px;
  }
`;

const ADailyContract = () => {
  const [datas, setDatas] = useState([]);

  const fetchUsers = async () => {
    const token = getToken();
    if (token) {
      const result = await axios.get("/users", {
        headers: {
          access_token: token,
        },
      });
      setDatas(result.data);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const headers = ["id", "name", "email", "phone", "created_at"];

  return (
    <NeedMaster>
      <Style>
        <h2>일일근로 계약서 관리</h2>
        <div className="list">
          <DataTable datas={datas} headers={headers} />
        </div>
      </Style>
    </NeedMaster>
  );
};

export default ADailyContract;
