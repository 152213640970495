import theme from 'styles/theme'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Button } from 'comp/Button'
import { Link } from 'react-router-dom'

const Style = styled.div`
  padding-top: 128px;
  text-align: center;
  h2 {
    font-weight: 400;
    color: ${theme.black};
    margin-bottom: 24px;
  }
  .check {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
`

const Success = () => {
  return (
    <Style>
      <div className="check">
        <img src="/assets/ic-check.svg"/>
      </div>
      <h2>제출 성공</h2>
      <Link to="/">
        <Button>홈으로 돌아가기</Button>
      </Link>
    </Style>
  )
}

export default observer(Success)
