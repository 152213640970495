import theme from 'styles/theme'
import { useInput } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Input, Label } from 'comp/Input'
import { Button } from 'comp/Button'
import axios from 'axios'
import { useEffect } from 'react'
import { authReqeust } from 'utils'
import NeedMaster from 'layout/NeedMaster'
import { useParams } from 'react-router-dom'

const Style = styled.div`
  padding: 48px 16px;
  max-width: 768px;
  margin: 0 auto;
  h2 {
    font-weight: 400;
    margin-bottom: 32px;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 16px;
  }
  .forms {
    padding: 12px 0;
    border-top: 1px solid ${theme.nd(0.08)};
    border-bottom: 1px solid ${theme.nd(0.08)};
    margin-bottom: 24px;
    .social-number {
      & > * {
        margin-right: 8px;
      }
      input {
        width: 100px;
      }
    }
    .postal-code {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 8px;
      }
      input {
        width: 100px;
      }
    }
  }
  .agreements {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      input {
        margin-right: 8px;
      }
      a {
        color: ${theme.gold};
        text-decoration: underline;
      }
    }
  }
  .action {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
`

const EditUserInfo = ({ history }) => {
  const queryParams = useParams()
  const [ state, onChange ] = useInput({
    name: '',
    phone: '',
    social_number1: '',
    social_number2: '',
    post_code: '',
    address1: '',
    address2: '',
    bank_account: '',
    bank_name: '',
    bank_username: '',
  })

  const fetchUserData = async () => {
    const result = await authReqeust(axios.get, `/users/${queryParams.id}`)
    const user = { ...result.data }

    delete user.id
    delete user.is_master
    delete user.created_at
    delete user.updated_at

    Object.keys(user).forEach(key => {
      onChange({
        target: {
          name: key,
          value: user[key]
        }
      })
    })
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  const submit = async () => {
    const arr = Object.keys(state).reduce((acc, value) => {
      acc.push(state[value]);
      return acc;
    }, [])

    const len = arr.length;

    for (let i = 0; len > i; i++ ) {
      if (arr[i].trim() === '') return alert('모든 항목을 입력해주세요');
    }

    const params = { ...state };

    await authReqeust(axios.patch, `/users/${queryParams.id}`, params)
    history.push(`/admin/users/${queryParams.id}`);
  }
  
  return (
    <NeedMaster>
      <Style>
        <h2>회원 정보 수정</h2>
        <h3>개인정보</h3>
        <div className="forms">
          <Label label="이메일">
            <Input name="email" value={state.email} placeholder="abc@gmail.com" type="email" disabled/>
          </Label>
          <Label label="이름">
            <Input name="name" value={state.name} placeholder="실명을 입력해주세요" type="text" onChange={onChange}/>
          </Label>
          <Label label="주민등록번호">
            <div className="social-number">
              <Input name="social_number1" value={state.social_number1} placeholder="950101" type="text" onChange={onChange}/>
              <span>-</span>
              <Input name="social_number2" value={state.social_number2} placeholder="0000000" type="text" onChange={onChange}/>
            </div>
          </Label>
          <Label label="휴대폰 번호">
            <Input name="phone" value={state.phone} placeholder="- 를 제외하고 입력해주세요" type="text" onChange={onChange}/>
          </Label>
          <Label label="주소">
            <div className="postal-code">
              <Input name="post_code" value={state.post_code} placeholder="우편번호" type="text" onChange={onChange}/>
              <Button size="small">주소 검색</Button>
            </div>
          </Label>
          <Label empty>
            <Input name="address1" value={state.address1} placeholder="기본주소" type="text" onChange={onChange}/>
          </Label>
          <Label empty>
            <Input name="address2" value={state.address2} placeholder="상세주소" type="text" onChange={onChange}/>
          </Label>
        </div>
        <h3>급여계좌 정보</h3>
        <div className="forms">
          <Label label="은행명">
            <Input name="bank_name" value={state.bank_name} placeholder="은행명을 입력해주세요" type="text" onChange={onChange}/>
          </Label>
          <Label label="계좌번호">
            <Input name="bank_account" value={state.bank_account} placeholder="- 를 제외하고 입력해주세요" type="text" onChange={onChange}/>
          </Label>
          <Label label="예금주">
            <Input name="bank_username" value={state.bank_username} placeholder="예금주를 정확히 입력해주세요" type="text" onChange={onChange}/>
          </Label>
        </div>
        <div className="action">
          <Button onClick={submit}>수정하기</Button>
        </div>
      </Style>
    </NeedMaster>
  )
}

export default observer(EditUserInfo)
