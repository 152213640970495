import { useHistory } from "react-router-dom";
import styled from "styled-components";
import theme from "styles/theme";

const Table = styled.table`
  width: 100%;
  border: 1px solid ${theme.nsd(0.08)};
  font-size: 14px;
  font-weight: 500;
  th {
    text-align: left;
    background-color: ${theme.nsd(0.02)};
    border: 1px solid ${theme.nsd(0.08)};
    padding: 8px;
    &.action {
      width: 240px;
    }
  }
  tr {
    &:hover {
      background-color: ${theme.nd(0.04)};
    }
    td {
      padding: 4px 8px;
      border: 1px solid ${theme.nsd(0.08)};
      &.action {
        cursor: pointer;
        color: ${theme.positive(0.5)};
        text-decoration: underline;
      }
    }
  }
`;

const DataTable = ({
  datas,
  headers,
  tableName,
  onDeleteClick,
  hasNextPage,
  fetchNextPage,
}) => {
  const history = useHistory();
  return (
    <>
      <Table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {datas.map((data) => (
            <tr key={data.id}>
              {headers.map((key) => (
                <td key={key}>{data[key]}</td>
              ))}
              <td
                className="action"
                onClick={() => {
                  history.push(`/admin/${tableName}/${data.id}`);
                }}
              >
                상세
              </td>
              {onDeleteClick && (
                <td className="action" onClick={() => onDeleteClick(data.id)}>
                  삭제
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {hasNextPage && (
        <div
          onClick={fetchNextPage}
          style={{
            display: "flex",
            height: 40,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          더 불러오기
        </div>
      )}
    </>
  );
};

export default DataTable;
