import theme from 'styles/theme'
import { useInput, useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import NeedLogin from 'layout/NeedLogin'
import { Button } from 'comp/Button'
import { Input, Label } from 'comp/Input'
import axios from 'axios'
import dayjs from 'dayjs'
import { getToken } from 'utils'

const Style = styled.div`
  padding: 48px 16px;
  max-width: 768px;
  margin: 0 auto;
  h2 {
    font-weight: 400;
    margin-bottom: 16px;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 16px;
  }
  .statement {
    font-size: 14px;
    margin-bottom: 24px;
    line-height: 1.6;
  }
  .forms {
    padding: 12px 0;
    border-top: 1px solid ${theme.nd(0.08)};
    border-bottom: 1px solid ${theme.nd(0.08)};
    margin-bottom: 24px;
    .work-date {
      width: 100px;
    }
  }
  .description {
    font-size: 12px;
    color: ${theme.nd(0.6)};
    padding-bottom: 24px;
  }
`

const DailyContract = ({ history }) => {
  const { userStore } = useStore()
  const [ state, onChange ] = useInput({
    work_address: '',
    work_position: '',
    wage_per_hour: '',
    work_start_at: '',
    work_end_at: '',
  })

  const submit = async () => {
    const arr = Object.keys(state).reduce((acc, value) => {
      acc.push(state[value]);
      return acc;
    }, [])

    const len = arr.length;

    for (let i = 0; len > i; i++ ) {
      if (arr[i].trim() === '') return alert('모든 항목을 입력해주세요');
    }

    const params = { 
      user_id: userStore.currentUser.id,
      ...state
    };

    try {
      const token = getToken()
      await axios.post('/daily-contracts', params, {
        headers: {
          access_token: token
        }
      });
      history.push('/success');
    } catch(e) {
      console.log(e)
      alert(e.response.data.message)
    }
  }

  return (
    <NeedLogin>
      <Style>
        <h2>
          일일근로계약서
        </h2>
        <h3>근로계약내용</h3>
        <p className="statement">
          1.계약기간 및 근무장소 : 본 근로계약은 1일 단위의 근로계약이며, 업무시간 종료시 자동 계약만료된다. 근무장소는 사용자와 근로자간 사전에 협의한 위 근로장소.<br/>
          <br/>
          2.휴게시간: 11시~11시30분/16시30분~17:00 [1일 1시간] ※단, 휴게시간은 행사 성격에 따라 변경될수있으며, 휴게시간은 임금에 산정하지 않는다.<br/>
          <br/>
          3.적용제외: 근로기준범상 단기간 근로자는 근로기준법의 주휴일, 연차휴가, 퇴직금 규정을 적용하지 아니하며, 기타 관련 사항은 회사 규정 및 관련법규에 따른다.<br/>
          <br/>
          4.고용보험: 임금지급시 근로자부담금 고용보험료 3.3%는 공제후 입금되며, 본계약에 명시하지 아니한 사항은 사용자가 별도로 정한 회사규정 및 근로기준법 등을 적용한다.<br/>
          <br/>
          5.개인정보: 본계약과 관련하여 사용자는 위 개인정보를 엄격히 관리하며, 근로자는 위 사용자가 근로소득관련 제출증빙등의 목적으로 근로자의 개인정보 보관등에 관하여 동의한다.<br/>
        </p>
        <div className="forms">
          <Label label="이름">
            <Input name="name" type="text" value={userStore.currentUser.name} disabled/>
          </Label>
          <Label label="주민등록번호">
            <Input name="social_number1" type="text" value={userStore.currentUser.social_number1} disabled/>
          </Label>
          <Label label="전화번호">
            <Input name="phone" type="text" value={userStore.currentUser.phone} disabled/>
          </Label>
          <Label label="주소">
            <Input name="address" type="text" value={userStore.currentUser.address1 + ' ' + userStore.currentUser.address2} disabled/>
          </Label>
          <Label label="근무장소">
            <Input name="work_address" type="text" value={state.work_address} onChange={onChange} placeholder=""/>
          </Label>
          <Label label="해당업무">
            <Input name="work_position" type="text" value={state.work_position} onChange={onChange} placeholder=""/>
          </Label>
          <Label label="근무시급">
            <Input name="wage_per_hour" type="text" value={state.wage_per_hour} onChange={onChange} placeholder=""/> 원
          </Label>
          <Label label="근무날짜">
            <Input className="work-date" name="work_start_at" type="text" value={state.work_start_at} onChange={onChange} placeholder="210101"/>
            <span>-</span>
            <Input className="work-date" name="work_end_at" type="text" value={state.work_end_at} onChange={onChange} placeholder="210201"/>
          </Label>
          <Label label="계약서 작성일자">
            <Input type="text" value={dayjs(new Date()).format('YYYY-MM-DD')} disabled/>
          </Label>
        </div>
        <div className="action">
          <Button onClick={submit}>계약서 제출</Button>
        </div>
      </Style>
    </NeedLogin>
  )
}

export default observer(DailyContract)
