import styled from 'styled-components'

const Style = styled.div`
  padding-top: 48px;
  max-width: 768px;
  margin: 0 auto;
  h2 {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 24px;
    line-height: 1.5;
  }
  ul {
    margin: 8px;
  }
`

const PrivacyPolicy = () => {
  return (
    <Style>
      <h2>개인정보 처리방침</h2>
      <p>
        야크는 개인정보보호문제를 신중하게 취급합니다.가입하신 정보는 회원님의 동의없이 공개되지 않으며, 개인정보보호정책에 의해 보호를 받습니다.
      </p>
      <p>
        01_ 개인정보의 수집 이용 목적
        <ul>
          <li>
            1) 회원제 서비스 이용에 따른 본인확인, 본인의사 확인, 연령제한 서비스 이용
          </li>
          <li>
            2) 세법 등 관련법에 의한 신고의무에 따른 제반 신고에 이용
          </li>
          <li>
            3) 고지사항 전달, 불만처리 의사소통 경로 확보
          </li>
          <li>
            4) 신규 서비스 등 최신정보 안내 및 개인 맞춤서비스 제공을 위한 자료
          </li>
          <li>
            5) 불량 회원 부정이용방지 및 비인가 사용 방지
          </li>
          <li>
            6) 기타 원활한 양질의 서비스 제공 등
          </li>
        </ul>
      </p>
      <p>
        02_ 수집하는 개인정보 항목 및 수집 방법별도의 회원가입 없이도 대부분의 컨텐츠에 자유롭게 접근할 수 있습니다. 하지만 보다 다양하고 양질의 서비스를 원하실 경우 아래의 내용을 입력하신 후 회원가입을 하시면 모든 서비스를 편리하게 이용하실 수 있으며, 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 시 홈페이지를 통해 아래와 같은 개인정보를 수집하고 있습니다.

        <ul>
          <li>
            1) 수집하는 개인정보의 항목
            <ul>
              <li>
                ① 회원가입 시 수집하는 항목
                <ul>
                  <li>
                    ㄱ. 필수항목 : 이름, 로그인ID, 비밀번호, 생년월일, 성별, 연락처(자택전화번호, 휴대폰번호), e-메일, 회원사진, 학업상태, 최종학력, 집주소, 근무가능지역, 근무경험여부, 근복무여부, 신장, 체중, 보건증발급여부, 급여지급계좌, 자기소개 및 경력, 접속 로그, 쿠키, 접속 IP 정보<br/>
                  </li>
                </ul>
              </li>
              <li>
                ② 근무 후 수집하는 항목
                <ul>
                  <li>
                    ㄱ. 필수항목 : 개인식별번호( 주민등록번호 )
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            2) 개인정보 수집 방법홈페이지 회원가입, 서비스 이용
          </li>
          <li>
            3) 개인식별번호(주민등록번호)의 수집은 소득세법 제164조(동법 시행규칙 서식 24(4))에 의한 지급명세 제출 및 고용보험 및 산업재해보상보험의 보험료징수 등에 관한 법률 시행규칙 제16조의6(서식22의7)에 의한 근로내용확인 신고의 목적으로만 이용되어 집니다.
          </li>
        </ul>  
      </p>
      <p>
        03_ 개인정보의 이용기간 및 보유기간"회사"는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 회원탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 이용기간이 종료한 경우 개인정보를 지체 없이 파기합니다. 단, 다음의 경우에 대해서는 각각 명시한 이유와 기간 동안 보존합니다.
        <ul>
          <li>
            1) 세법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용합니다.
            <ul>
              <li>
                ① 근로 후 임금 지급 후 지급 시기로부터: 3년
              </li>
              <li>
                ② 회원 불만 또는 분쟁 처리에 관련된 기록: 3년
              </li>
              <li>
                ③ 부정 이용 등에 관한 기록 : 3년
              </li>
              <li>
                ④ 웹사이트 방문기록(로그인 기록, 접속기록, 근무 지원 기록) : 3년
              </li>
            </ul>
          </li>
          <li>
            2) 보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 동의를 받은 경우에는 약정한 기간 동안 보유합니다. 다만, 임금지급 자료는 그러하지 않습니다.
          </li>
        </ul>
      </p>
    </Style>
  )
}

export default PrivacyPolicy
