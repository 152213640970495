import styled from "styled-components";
import NeedMaster from "layout/NeedMaster";
import { useEffect, useState } from "react";
import axios from "axios";
import { authReqeust, buildObjQuery, ExportCsv } from "utils";
import DataTable from "comp/DataTable";
import dayjs from "dayjs";
import { Button } from "comp/Button";
import xlsx from "json-as-xlsx";

const Style = styled.div`
  margin-top: 48px;
  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .list {
    margin-top: 48px;
    overflow: auto;
  }
  .search-type {
    display: flex;
    margin-top: 24px;
    div {
      padding-right: 16px;
      & > input {
        margin-right: 4px;
      }
    }
  }
  .search-section {
    display: flex;
    margin-top: 16px;
    & > * {
      margin-right: 8px;
    }
  }
`;

const Admin = () => {
  const [datas, setDatas] = useState({
    count: 0,
    data: [],
    page: 0,
  });
  const [currKeyword, setCurrKeyword] = useState();
  // name, email, phone, birth
  const [type, setType] = useState("name");

  const searchTypes = [
    {
      value: "name",
      label: "이름",
    },
    {
      value: "email",
      label: "이메일",
    },
    {
      value: "phone",
      label: "연락처",
    },
    {
      value: "social_number1",
      label: "생년월일",
    },
  ];

  const fetchUsers = async (page, type, keyword) => {
    const query = buildObjQuery({
      page: page || 0,
      "search-type": keyword ? type : null,
      keyword: keyword || null,
    });
    setCurrKeyword(keyword);
    const result = await authReqeust(axios.get, `/users${query}`);
    console.log(page, result.data);
    if (!page || page == 0) {
      console.log(1);
      setDatas(result.data);
    } else {
      console.log(2);
      setDatas((prev) => {
        return {
          ...result.data,
          data: [...prev.data, ...result.data.data],
        };
      });
    }
  };

  const onKeyDownHandler = async (e) => {
    if (e.isComposing) return;
    if (e.key === "Enter") {
      await fetchUsers(0, type, e.target.value);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const headers = [
    "id",
    "name",
    "email",
    "phone",
    "social_number",
    "bank_name",
    "bank_account",
    "bank_username",
    "created_at",
  ];

  const computedData = datas.data.reduce((acc, value) => {
    const row = {
      ...value,
      social_number: `${value.social_number1}-${value.social_number2}`,
      created_at: dayjs(value.created_at).format("YYYY-MM-DD"),
    };

    delete row.social_number1;
    delete row.social_number2;

    acc.push(row);
    return acc;
  }, []);

  const downloadCSV = async () => {
    const result = await authReqeust(axios.get, `/users/xlsx`);
    const excelData = result.data.map((value) => {
      const row = {
        ...value,
        social_number: `${value.social_number1}${value.social_number2}`,
        created_at: dayjs(value.created_at).format("YYYY-MM-DD"),
      };

      delete row.social_number1;
      delete row.social_number2;

      return row;
    });

    const columns = headers.reduce((acc, key) => {
      acc.push({
        label: key,
        value: key,
      });
      return acc;
    }, []);

    const data = [
      {
        sheet: "Users",
        columns: columns,
        content: excelData,
      },
    ];

    const settings = {
      fileName: "users",
    };

    xlsx(data, settings);
  };

  return (
    <NeedMaster>
      <Style>
        <div className="top-section">
          <h2>회원 관리</h2>
          <Button onClick={downloadCSV}>엑셀 다운로드</Button>
        </div>
        <div className="search-type">
          {searchTypes.map((item) => (
            <div key={item.value}>
              <input
                type="radio"
                id={item.value}
                value={item.value}
                name="searchType"
                checked={type === item.value}
                onChange={() => {
                  setType(item.value);
                }}
              />
              <label htmlFor={item.value}>{item.label}</label>
            </div>
          ))}
        </div>
        <div className="search-section">
          <input
            type="text"
            id="search-input"
            placeholder="키워드입력"
            name="search"
            onKeyDown={onKeyDownHandler}
          />
        </div>
        <div className="search-section">
          <Button
            onClick={() => {
              const input = document.querySelector("#search-input").value;
              fetchUsers(0, type, input);
            }}
          >
            검색
          </Button>
          <Button
            secondary
            onClick={() => {
              const input = document.querySelector("#search-input");
              input.value = "";
              fetchUsers();
            }}
          >
            초기화
          </Button>
        </div>
        <div className="list">
          <DataTable
            datas={computedData}
            headers={headers}
            tableName="users"
            hasNextPage={datas.count > datas.data.length}
            fetchNextPage={() => {
              fetchUsers(datas.page + 1, type, currKeyword);
            }}
          />
        </div>
      </Style>
    </NeedMaster>
  );
};

export default Admin;
