import styled from 'styled-components'
import theme from 'styles/theme'

export const Input = styled.input`
  background-color: ${theme.nd(0.04)};
  color: ${theme.black};
  font-size: 16px;
  border: none;
  appearance: none;
  padding: 8px 16px;
  width: 300px;
  &::placeholder {
    color: ${theme.nd(0.3)};
  }
  &:disabled {
    background-color: ${theme.nl(1)};
  }
`

const CheckInputStyle = styled.input`
  &[type="checkbox"] {
    position: relative;
    width: 24px;
    height: 24px;
    appearance: none;
    background-color: ${theme.nl(1)};
    box-shadow: 0 0 0 1px inset ${theme.black};
    cursor: pointer;
    &::before {
      content: " ";
      position: absolute;
      top: 4px;
      left: 4px;
      width: 16px;
      height: 16px;
      background-color: ${theme.black};
      transform: scale(0);
      transition: transform 0.15s;
    }
    &:checked {
      &::before {
        transform: scale(1);
      } 
    }
  }
`

export const CheckInput = props => <CheckInputStyle type="checkbox" {...props}/>

const LabelStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  min-height: 48px;
  .label {
    color: ${theme.black};
    width: 180px;
    font-weight: 500;
    .star {
      padding-left: 4px;
      color: ${theme.pink};
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 16px;
    .label {
      padding-bottom: 8px;
    }
  }
`

export const Label = ({ label, children, empty }) => {
  return (
    <LabelStyle>
      <div className="label">
        { !empty && (
          <>
            { label }
            <span className="star">
              *
            </span>
          </>
        )}
      </div>
      <div>
        { children }
      </div>
    </LabelStyle>
  )
}