import styled, { css } from 'styled-components'
import theme from 'styles/theme'

export const Button = styled.button`
  background-color: ${theme.black};
  color: ${theme.nl(1)};
  font-size: 12px;
  padding: 11px 24px;
  ${({size}) => size === 'small' && css`
    padding: 8px 16px;
  `}
  ${({secondary}) => secondary && css`
    background-color: ${theme.nl(0)};
    color: ${theme.black};
    box-shadow: 0 0 0 1px inset ${theme.black};
  `}
`