import theme from 'styles/theme'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

const Style = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    position: relative;
    width: 100%;
    padding-top: 100%;
    background-image: url(${({image_url}) => image_url});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 24px;
  }
  .bar {
    width: 48px;
    height: 2px;
    background-color: ${theme.black};
    margin-bottom: 16px;
  }
  .info {
    font-weight: 600;
  }
`

const CompanyItem = props => {
  const { image_url, name } = props
  return (
    <Style image_url={image_url}>
      <div className="image"></div>
      <div className="bar"></div>
      <div className="info">
        { name }
      </div>
    </Style>
  )
}

export default CompanyItem
