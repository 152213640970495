import theme from 'styles/theme'
import { useInput, useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Input, Label } from 'comp/Input'
import { Button } from 'comp/Button'
import axios from 'axios'
import NeedLogin from 'layout/NeedLogin'
import { authReqeust, setToken } from 'utils'

const Style = styled.div`
  padding: 48px 16px;
  max-width: 768px;
  margin: 0 auto;
  h2 {
    font-weight: 400;
    margin-bottom: 32px;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 16px;
  }
  .forms {
    padding: 12px 0;
    border-top: 1px solid ${theme.nd(0.08)};
    border-bottom: 1px solid ${theme.nd(0.08)};
    margin-bottom: 24px;
  }
  .action {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
`

const EditPassword = ({ history }) => {
  const { userStore } = useStore()
  const [ state, onChange ] = useInput({
    password: '',
    re_password: '',
  })

  const submit = async () => {
    const arr = Object.keys(state).reduce((acc, value) => {
      acc.push(state[value]);
      return acc;
    }, [])

    const len = arr.length;

    for (let i = 0; len > i; i++ ) {
      if (arr[i].trim() === '') return alert('모든 항목을 입력해주세요');
    }

    const params = { ...state };

    delete params.re_password

    try {
      const result = await authReqeust(axios.patch, '/auth/change-password', params)
      const { user, access_token } = result.data;
      setToken(access_token);
      userStore.setCurrentUser(user);

      history.push('/mypage');
    } catch(e) {
      console.log(e)
      alert(e.response.data.message)
    }
  }
  
  return (
    <NeedLogin>
      <Style>
        <h2>비밀번호 수정</h2>
        <div className="forms">
          <Label label="새 비밀번호">
            <Input name="password" value={state.password} placeholder="6 ~ 16자리 비밀번호" type="password" onChange={onChange}/>
          </Label>
          <Label label="비밀번호 확인">
            <Input name="re_password" value={state.re_password} placeholder="6 ~ 16자리 비밀번호 재입력" type="password" onChange={onChange}/>
          </Label>
        </div>
        <div className="action">
          <Button onClick={submit}>수정하기</Button>
        </div>
      </Style>
    </NeedLogin>
  )
}

export default observer(EditPassword)
