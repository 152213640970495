import theme from 'styles/theme'
import { useInput, useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Input, Label } from 'comp/Input'
import { Button } from 'comp/Button'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { setToken } from 'utils'

const Style = styled.div`
  padding: 48px 16px;
  max-width: 480px;
  margin: 0 auto;
  h2 {
    font-weight: 400;
    margin-bottom: 32px;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 16px;
  }
  .forms {
    padding: 12px 0;
    border-top: 1px solid ${theme.nd(0.08)};
    border-bottom: 1px solid ${theme.nd(0.08)};
    margin-bottom: 24px;
    .item {
      margin-bottom: 8px;
    }
  }
  .action {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    button {
      margin: 0 8px;
    }
  }
  .callout-section {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
  }
  .callout {
    padding: 10px 24px;
    background-color: ${theme.positive(0.1)};
    color: ${theme.positive(1)};
    border: 1px solid ${theme.positive(1)};
  }
`

const Login = ({ history }) => {
  const { userStore } = useStore()
  const query = new URLSearchParams(useLocation().search)

  const [ state, onChange ] = useInput({
    email: '',
    password: '',
  })

  const login = async () => {
    try {
      await userStore.login(state)
      history.push('/')
    } catch(e) {
      alert(e.response.data.message)
    }
  }

  return (
    <Style>
      {query.get('signup') === 'success' &&
        <div className="callout-section">
          <div className="callout">회원가입이 완료되었습니다.</div>
        </div>
      }
      <h2>로그인</h2>
      <div className="forms">
        <Label label="이메일">
          <Input name="email" type="email" value={state.email} onChange={onChange} placeholder="abc@gmail.com" onKeyUp={e => e.key === 'Enter' && login()}/>
        </Label>
        <Label label="비밀번호">
          <Input name="password" type="password" value={state.password} onChange={onChange} placeholder="*********" onKeyUp={e => e.key === 'Enter' && login()}/>
        </Label>
      </div>
      <div className="action">
        <Button onClick={login}>로그인</Button>
        <Link to="/signup">
          <Button secondary>회원가입</Button>
        </Link>
      </div>
    </Style>
  )
}

export default observer(Login)
