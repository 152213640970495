import theme from 'styles/theme'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Style = styled.div`
  margin-top: 128px;
  background-color: ${theme.nd(0.8)};
  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 48px 24px;
    color: ${theme.nl(1)};
    line-height: 1.6;
    a {
      color: ${theme.nl(1)};
    }
  }
`

const Footer = () => {
  return (
    <Style>
      <div className="wrapper">
        상호 : 야크에이전시<br/>
        주소 : 서울특별시 종로구 세종대로 23길 47, 603호<br/>
        연락처 : 010-2676-2216 / 010-4480-0611 / 010-2180-9826<br/>
        Copyright © 2019 야크에이전시 Corp. All Right Reserved.<br/><br/><br/>
        <Link to="/agreements/privacy-policy">
          개인정보 처리방침
        </Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to="/agreements/service-policy">
          서비스 이용약관
        </Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to="/agreements/signup-agreement">
          회원가입 약관
        </Link>
      </div>
    </Style>
  )
}

export default Footer
