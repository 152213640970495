const setAlpha = (rgb, alpha) => alpha === 0 || alpha ? `rgba(${rgb}, ${alpha})` : `rgba(${rgb}, 1)`;

const convertHex = (rgb, alpha) => {
  const convertedValue = Math.floor(255 - (alpha * 255));
  return `rgba(${convertedValue}, ${convertedValue}, ${convertedValue}, 1)`;
}

const theme = {
  black: '#3C3C3C',
  gold: '#D29700',
  pink: '#FF2070',
  // neutral color variables
  nd: alpha => setAlpha('0,0,0', alpha),
  nl: alpha => setAlpha('255,255,255', alpha),
  nsd: alpha => convertHex('0, 0, 0', alpha),
  positive: alpha => setAlpha('65,110,255', alpha),
  negative: alpha => setAlpha('239,31,65', alpha),
  alert: alpha => setAlpha('255,142,16', alpha),
}

export default theme;