import theme from 'styles/theme'
import { useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Button } from 'comp/Button'
import { Link } from 'react-router-dom'
import CompanyItem from 'comp/CompanyItem'

const Style = styled.div`
  section.hero {
    padding: 86px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 200px;
    }
    .message {
      padding-left: 45px;
      transform: translateY(-10px);
      h1 {
        font-size: 32px;
        line-height: 45px;
        font-weight: 300;
        color: ${theme.black};
      }
      .action {
        margin-top: 28px;
      }
    }
    @media (max-width: 768px) {
      padding: 48px 24px;
      flex-direction: column;
      .message {
        padding: 24px 0;
        h1 {
          font-size: 20px;
          line-height: 1.6;
        }
      }
    }
  }
  section.notice {
    h2 {
      font-weight: 400;
      margin-bottom: 24px;
    }
    .list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 24px;
      row-gap: 48px;
      @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .action {
      margin-top: 64px;
      text-align: center;
    }
  }
  
`

const Home = ({ history }) => {
  const store = useStore()
  return (
    <Style>
      <section className="hero">
        <div className="logo">
          <img src="/assets/ic-logo.png" alt="yak agency" />
        </div>
        <div className="message">
          <h1>
            항상 준비된 자세로 전문적인 인재개발을 통한<br/>
            최고의 인력 지원서비스
          </h1>
          <div className="action">
            <Link to="/login">
              <Button>회원가입 / 로그인</Button>
            </Link>
          </div>
        </div>
      </section>
      <section className="notice">
        <h2>장단기알바 채용정보</h2>
        <div className="list">
          <CompanyItem image_url="/assets/company/fourseasons.jpeg" name="포시즌스호텔 서울광화문"/>
          <CompanyItem image_url="/assets/company/raum.jpeg" name="라움아트센터 강남"/>
          <CompanyItem image_url="/assets/company/alof.jpeg" name="알로프트호텔 명동"/>
          <CompanyItem image_url="/assets/company/kyungwonjae.jpeg" name="경원재 앰배서더 인천송도"/>
        </div>
        <div className="action">
          <a href="tel:010-2180-9826">
            <Button>☎ 담당자에게 연락하기</Button>
          </a>
        </div>
      </section>
      
    </Style>
  )
}

export default observer(Home)
