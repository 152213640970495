import { useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Button } from 'comp/Button'
import NeedLogin from 'layout/NeedLogin'
import { Divider, Division } from 'comp'
import { Link } from 'react-router-dom'
import { Label } from 'comp/Input'
import { useEffect } from 'react'

const Style = styled.div`
  padding-top: 64px;
  max-width: 768px;
  margin: 0 auto;
  h1, h3 {
    font-weight: 400;
  }
  .actions {
    & > * {
      margin-right: 8px;
    }
  }
`

const Mypage = ({ history }) => {
  const { userStore } = useStore();
  const { currentUser } = userStore;

  useEffect(() => {
    userStore.getAuth();
  }, [])
  
  const logout = () => {
    userStore.logout();
    history.push('/');
  }

  return (
    <NeedLogin>
      <Style>
        <h1>마이페이지</h1>
        <Division height="24px"/>
        <h3>회원 정보</h3>
        <Division height="16px"/>
        <Divider/>
        <Label label="이름">
          {currentUser.name}
        </Label>
        <Label label="전화번호">
          {currentUser.phone}
        </Label>
        <Label label="이메일">
          {currentUser.email}
        </Label>
        <Label label="주소">
          {`${currentUser.address1} ${currentUser.address2}`}
        </Label>
        <Division height="24px"/>
        <h3>환불 계좌 정보</h3>
        <Division height="16px"/>
        <Divider/>
        <Label label="계좌번호">
          {currentUser.bank_account}
        </Label>
        <Label label="은행명">
          {currentUser.bank_name}
        </Label>
        <Label label="예금주">
          {currentUser.bank_username}
        </Label>
        <Division height="24px"/>
        <div className="actions">
          <Link to="/mypage/edit">
            <Button secondary>내 정보 변경</Button>
          </Link>
          <Link to="/mypage/edit-password">
            <Button secondary>비밀번호 변경</Button>
          </Link>
          <Button onClick={logout}>로그아웃</Button>
        </div>
      </Style>
    </NeedLogin>
  )
}

export default observer(Mypage)
