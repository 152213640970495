import { useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Button } from 'comp/Button'
import { useHistory } from 'react-router-dom'

const Style = styled.div`
  text-align: center;
  padding-top: 128px;
  h2 {
    font-weight: 400;
    padding-bottom: 32px;
  }
`

const NeedLogin = ({ children }) => {
  const { userStore } = useStore()
  const history = useHistory();
  
  if (!userStore.isLogin) return (
    <Style>
      <h2>로그인이 필요한 페이지입니다.</h2>
      <Button onClick={() => history.push('/login')}>로그인 / 회원가입</Button>
    </Style>
  )

  return (
    <>
      { children }
    </>
  )
}

export default observer(NeedLogin)
