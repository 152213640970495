import { useContext, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import stores from "stores";

export const useStore = () => {
  const store = useContext(stores);
  return store;
}

const reducer = (state, action) => {
  return {
    ...state,
    [action.name]: action.value
  };
}

export const useInput = initialForm => {
  const [state, dispatch] = useReducer(reducer, initialForm);
  const onChange = e => {
    dispatch(e.target);
  };
  return [state, onChange];
}

export const useLogin = () => {
  const { userStore } = useStore();
  const history = useHistory();
  useEffect(() => {
    if (!userStore.isLogin) {
      history.push('/login')
    }
  }, [])
}