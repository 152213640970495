import styled from 'styled-components'
import { Button } from 'comp/Button'
import { Link } from 'react-router-dom'

const Style = styled.div`
  padding-top: 144px;
  text-align: center;
  h2 {
    font-weight: 400;
    margin-bottom: 48px;
  }
`

const Hiring = ({ history }) => {
  return (
    <Style>
      <h2>준비중입니다</h2>
      <Link to="/">
        <Button>홈으로 돌아가기</Button>
      </Link>
    </Style>
  )
}

export default Hiring