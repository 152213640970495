import styled from 'styled-components'

const Style = styled.div`
  padding-top: 48px;
  max-width: 768px;
  margin: 0 auto;
  h2 {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 24px;
    line-height: 1.5;
  }
  ul {
    margin: 8px;
  }
`

const SignupAgreement = () => {
  return (
    <Style>
      <h2>회원가입 약관</h2>
      <p>
      제1장 총칙
<br/>
<br/>제1조[목적]
<br/>
<br/>이 약관은 야크에이전시가 제공하는  웹사이트 ([www.yakagy.com](http://www.yakagy.com/))의 서비스[이하 서비스라고 합니다]를 이용함에 있어 회사와 이용자 간의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
<br/>
<br/>제2조(용어의 정의)
<br/>
<br/>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
<br/>
<br/>1. 회원이라 함은 웹사이트에 회원으로 가입하여 회원제 서비스를 이용하는 이용자를 말합니다.
<br/>
<br/>2. 회원계약이라 함은 서비스 이용과 관련하여 회사와 회원간에 체결하는 계약을 말합니다.
<br/>
<br/>3. 회원가입이라 함은 회사가 제공하는 신청서 양식에 따라 해당정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위를 말합니다.
<br/>
<br/>4. 아이디라 함은 회원의 식별과 서비스 이용을 위하여 회원 본인이 선정하고 회사가 부여하는 영문자와 숫자의 조합(4자~12자)을 말합니다.
<br/>
<br/>5. 비밀번호라 함은 회원이 부여 받은 아이디와 일치된 회원임을 확인하고 회원의 권익보호를 위하여 회원이 선정한 영문자와 숫자의 조합(6자~12자)을 말합니다.
<br/>
<br/>6. 이용해지라 함은 회사 또는 회원이 서비스 이용 이후그 이용계약을 종료하는 의사표시를 말합니다.
<br/>
<br/>제3조(약관의 효력과 변경)
<br/>
<br/>회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다
<br/>
<br/>① 이 약관의 서비스 화면에 게시하거나 공지사항 게시판 또는 기타의 방법으로 공지함으로써 효력이 발생됩니다.
<br/>
<br/>② 휴플럭스은 필요하다고 인정되는 경우 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 서비스 화면에 공지하며, 공지후 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
<br/>
<br/>③ 이용자가 변경된 약관에 동의하지 않는 경우 서비스 이용을 중단하고 본인의 회원등록을 취소할 수 있으며, 계속 사용하시는 경우에는 약관 변경에 동의한 것으로 간주되며 변경된 약관은 전항과 같은 방법으로 효력이 발생합니다.
      </p>
    </Style>
  )
}

export default SignupAgreement
