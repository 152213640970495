import Gnb from "comp/Gnb";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Login from "./pages/Login";
import "./reset.css";
import "./global.css";
import Home from "pages/Home";
import Hiring from "pages/Hiring";
import ParentAgreement from "pages/ParentAgreement";
import DailyContract from "pages/DailyContract";
import Footer from "comp/Footer";
import Signup from "pages/Signup";
import ServicePolicy from "pages/agreements/ServicePolicy";
import SignupAgreement from "pages/agreements/SignupAgreement";
import PrivacyPolicy from "pages/agreements/PrivacyPolicy";
import Mypage from "pages/Mypage/index";
import { useEffect } from "react";
import { useStore } from "hooks";
import { observer } from "mobx-react-lite";
import Success from "pages/Success";
import EditMyInfo from "pages/Mypage/EditMyInfo";
import LoadingLayer from "comp/LoadingLayer";
import axios from "axios";
import ScrollToTop from "hooks/ScrollToTop";
import ADailyContract from "pages/admin/[id]/ADailyContract";
import AParentAgreement from "pages/admin/[id]/AParentAgreement";
import admin from "pages/admin";
import UserDetail from "pages/admin/[id]";
import EditPassword from "pages/Mypage/EditPassword";
import EditUserInfo from "pages/admin/[id]/EditUserInfo";

const MainLayer = styled.div`
  padding: 56px 24px 0px;
  padding-top: 56px;
  max-width: 1440px;
  margin: 0 auto;
  min-height: calc(100vh - 300px);
`;

const App = () => {
  const store = useStore();
  const { userStore } = store;

  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        store.setIsLoading(true);
        return config;
      },
      function (error) {
        store.setIsLoading(false);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        store.setIsLoading(false);
        return response;
      },
      function (error) {
        store.setIsLoading(false);
        return Promise.reject(error);
      }
    );

    userStore.getAuth();
  }, [store, userStore]);

  return (
    <Router>
      <ScrollToTop />
      <Gnb />
      <MainLayer>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route exact path="/" component={Home} />
          <Route path="/hiring" component={Hiring} />
          <Route exact path="/mypage" component={Mypage} />
          <Route path="/mypage/edit" component={EditMyInfo} />
          <Route path="/mypage/edit-password" component={EditPassword} />
          <Route path="/success" component={Success} />
          <Route path="/parent-agreement" component={ParentAgreement} />
          <Route path="/daily-contract" component={DailyContract} />
          <Route path="/agreements/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/agreements/signup-agreement"
            component={SignupAgreement}
          />
          <Route path="/agreements/service-policy" component={ServicePolicy} />

          <Route exact path="/admin" component={admin} />
          <Route exact path="/admin/users/:id" component={UserDetail} />
          <Route exact path="/admin/users/:id/edit" component={EditUserInfo} />
          <Route
            exact
            path="/admin/agreements/:id"
            component={AParentAgreement}
          />
          <Route exact path="/admin/contracts/:id" component={ADailyContract} />
        </Switch>
      </MainLayer>
      <LoadingLayer />
      <Footer />
    </Router>
  );
};

export default observer(App);
