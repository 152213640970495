import theme from 'styles/theme'
import { useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Button } from 'comp/Button'
import { useState } from 'react'

const Style = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  box-shadow: 0px 4px 16px ${theme.nd(0.04)};
  background-color:${theme.nl(1)};
  z-index: 10000;
  & > .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 24px;
    .logo {
      width: 48px;
      margin-right: 24px;
    }
    .menu {
      display: flex;
      & > .item {
        margin-right: 24px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: ${theme.black};
      }
    }
    .gnb-action {
      position: absolute;
      right: 24px;
      & > a {
        margin-left: 8px;
      }
    }
    .mobile-menu {
      display: none;
    }
  }
  @media (max-width: 768px) {
    height: 48px;
    & > .wrapper {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menu, .gnb-action {
        display: none;
      }
      .logo {
        width: 40px;
        height: 40px;
      }
      .mobile-menu {
        display: block;
      }
    }
  }
`

const Gnb = () => {
  const { userStore } = useStore()
  const [ mobileMenuState, setMobileMenuState ] = useState(false);

  return (
    <Style>
      <div className="wrapper">
        <Link className="logo" to="/">
          <img src="/assets/ic-logo.png" alt="야크에이전시"/>
        </Link>
        <div className="menu">
          <Link to="/hiring" className="item">아르바이트 채용정보</Link>
          <Link to="/daily-contract" className="item">일일근로 계약서</Link>
          <Link to="/parent-agreement" className="item">미성년자 부모님 동의서</Link>
        </div>
        { userStore.isLogin ? (<div className="gnb-action">
          <Link to="/mypage">
            <Button>마이페이지</Button>
          </Link>
          { userStore.currentUser.is_master && (
            <Link to="/admin">
              <Button>관리자</Button>
            </Link>
          )}
          </div>
        ) : (
          <Link to="/login" className="gnb-action">
            <Button>회원가입 / 로그인</Button>
          </Link>
        )}
        <div className="mobile-menu" onClick={() => {setMobileMenuState(prev => !prev)}}>
          <img src="/assets/ic-menu.svg" alt="mobile menu"/>
        </div>
      </div>
      { mobileMenuState && <MobileMenu hideMenu={() => {setMobileMenuState(false)}}/>}
      
    </Style>
  )
}

const MobileMenuStyle = styled.div`
  .dim {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 48px);
    z-index: 14000;
    background-color: ${theme.nd(0.08)};
  }
  .wrapper {
    position: fixed;
    z-index: 15000;
    right: 0;
    top: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: calc(100vh - 48px);
    background-color: ${theme.nl(1)};
    a {
      text-align: right;
      width: 100%;
      padding: 16px 16px 16px 24px;
      color: ${theme.black};
      border-bottom: 1px solid ${theme.nd(0.08)};
    }
  }
`

const MobileMenu = ({ hideMenu }) => {
  const { userStore } = useStore();

  return (
    <MobileMenuStyle>
      <div className="dim" onClick={hideMenu}></div>
      <div className="wrapper">
        <Link to="/hiring" className="item" onClick={hideMenu}>아르바이트 채용정보</Link>
        <Link to="/daily-contract" className="item" onClick={hideMenu}>일일근로 계약서</Link>
        <Link to="/parent-agreement" className="item" onClick={hideMenu}>미성년자 부모님 동의서</Link>
        { userStore.isLogin ? (<>
            <Link to="/mypage" onClick={hideMenu}>
              마이페이지
            </Link>
            { userStore.currentUser.is_master && (
              <Link to="/admin" onClick={hideMenu}>
                관리자
              </Link>
            )}
            </>
          ) : (
            <Link to="/login" onClick={hideMenu}>
              회원가입 / 로그인
            </Link>
          )}
      </div>
    </MobileMenuStyle>    
  )
}

export default observer(Gnb)
