import theme from 'styles/theme'
import { useInput, useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import NeedLogin from 'layout/NeedLogin'
import { Button } from 'comp/Button'
import { Input, Label } from 'comp/Input'
import axios from 'axios'
import { getToken } from 'utils'

const Style = styled.div`
  padding: 48px 16px;
  max-width: 768px;
  margin: 0 auto;
  h2 {
    font-weight: 400;
    margin-bottom: 16px;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 16px;
  }
  .statement {
    font-size: 14px;
    margin-bottom: 24px;
    line-height: 1.6;
  }
  .forms {
    padding: 12px 0;
    border-top: 1px solid ${theme.nd(0.08)};
    border-bottom: 1px solid ${theme.nd(0.08)};
    margin-bottom: 24px;
    .work-date {
      width: 100px;
    }
  }
  .description {
    font-size: 12px;
    color: ${theme.nd(0.6)};
    padding-bottom: 24px;
  }
`

const ParentAgreement = ({ history }) => {
  const { userStore } = useStore()
  const [ state, onChange ] = useInput({
    parent_name: '',
    parent_birth: '',
    parent_phone: '',
    parent_relation: '',
    work_start_at: '',
    work_end_at: '',
  })

  const submit = async () => {
    const newState = { ...state }

    const arr = Object.keys(newState).reduce((acc, value) => {
      acc.push(newState[value]);
      return acc;
    }, [])

    const len = arr.length;

    for (let i = 0; len > i; i++ ) {
      if (arr[i].trim() === '') return alert('모든 항목을 입력해주세요');
    }

    const params = { 
      user_id: userStore.currentUser.id,
      ...state,
      file1: 'abc',
      file2: 'def'
    };

    try {
      const token = getToken();
      await axios.post('/parent-agreements', params, {
        headers: {
          access_token: token
        }
      });
      history.push('/success');
    } catch(e) {
      console.log(e)
      alert(e.response.data.message)
    }
  }

  return (
    <NeedLogin>
      <Style>
        <h2>
          미성년자 부모님 동의서
        </h2>
        <p className="statement">
          본인(위 보호자)은 위 상기인(연소근로자)이 야크에이전시에서 파트타임 아르바이트 근로를 하는 것에 대하여 동의합니다.
        </p>
        <div className="forms">
          <Label label="부모님 성함">
            <Input name="parent_name" type="text" value={state.parent_name} onChange={onChange} placeholder="실명을 입력해주세요"/>
          </Label>
          <Label label="부모님(후견인) 생년월일">
            <Input name="parent_birth" type="text" value={state.parent_birth} onChange={onChange} placeholder="예) 750101"/>
          </Label>
          <Label label="부모님(후견인) 휴대전화">
            <Input name="parent_phone" type="text" value={state.parent_phone} onChange={onChange} placeholder="예) 01011112222"/>
          </Label>
          <Label label="연소근로자와의 관계">
            <Input name="parent_relation" type="text" value={state.parent_relation} onChange={onChange} placeholder="예) 부, 모, 할머니 등"/>
          </Label>
          <Label label="연소근로자 이름">
            <Input type="text" value={userStore.currentUser.name} disabled/>
          </Label>
          <Label label="연소근로자 생년월일">
            <Input type="text" value={userStore.currentUser.social_number1} disabled/>
          </Label>
          <Label label="연소근로자 휴대전화">
            <Input type="text" value={userStore.currentUser.phone} disabled/>
          </Label>
          <Label label="연소근로자 주소">
            <Input type="text" value={userStore.currentUser.address1 + ' ' + userStore.currentUser.address2} disabled/>
          </Label>
          <div className="description">
            연소근로자의 정보 변경이 필요한 경우 마이페이지에서 정보를 수정해주세요
          </div>
          <Label label="근무날짜">
            <Input className="work-date" name="work_start_at" type="text" value={state.work_start_at} onChange={onChange} placeholder="210101"/>
            <span>-</span>
            <Input className="work-date" name="work_end_at" type="text" value={state.work_end_at} onChange={onChange} placeholder="210201"/>
          </Label>
        </div>
        <div className="action">
          <Button onClick={submit}>동의서 제출</Button>
        </div>
      </Style>
    </NeedLogin>
  )
}

export default observer(ParentAgreement)
