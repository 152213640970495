import theme from 'styles/theme'
import { useInput, useStore } from 'hooks'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { CheckInput, Input, Label } from 'comp/Input'
import { Button } from 'comp/Button'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useState } from 'react'
import FindAddress from 'comp/FindAddress'

const Style = styled.div`
  padding: 48px 16px;
  max-width: 768px;
  margin: 0 auto;
  h2 {
    font-weight: 400;
    margin-bottom: 32px;
  }
  h3 {
    font-weight: 400;
    margin-bottom: 16px;
  }
  .forms {
    padding: 12px 0;
    border-top: 1px solid ${theme.nd(0.08)};
    border-bottom: 1px solid ${theme.nd(0.08)};
    margin-bottom: 24px;
    .social-number {
      & > * {
        margin-right: 8px;
      }
      input {
        width: 100px;
      }
    }
    .postal-code {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 8px;
      }
      input {
        width: 100px;
      }
    }
  }
  .agreements {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      input {
        margin-right: 8px;
      }
      a {
        color: ${theme.gold};
        text-decoration: underline;
      }
    }
  }
  .action {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
`

const Signup = ({ history }) => {
  const store = useStore()
  const [ state, onChange ] = useInput({
    name: '',
    email: '',
    phone: '',
    post_code: '',
    address1: '',
    address2: '',
    social_number1: '',
    social_number2: '',
    bank_account: '',
    bank_name: '',
    bank_username: '',
    password: '',
    re_password: '',
  })

  const [ agreement1, setAgreement1 ] = useState(false)
  const [ agreement2, setAgreement2 ] = useState(false)

  const [ addressPopupState, setAddressPopupState ] = useState(false)

  const checkHandler = e => {
    if (e.target.name === 'agreement1') {
      setAgreement1(e.target.checked)
    }
    if (e.target.name === 'agreement2') {
      setAgreement2(e.target.checked)
    }
  }

  const showAddressPopup = () => {
    setAddressPopupState(true)
  }

  const addressHandler = data => {
    let fullAddress = data.address
    let extraAddress = ''
    
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName)
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '')
    }

    onChange({ target: {
      name: 'address1',
      value: fullAddress
    }})

    onChange({ target: {
      name: 'post_code',
      value: data.zonecode
    }})

    setAddressPopupState(false)
  }

  const submit = async () => {
    const arr = Object.keys(state).reduce((acc, value) => {
      acc.push(state[value]);
      return acc;
    }, [])

    const len = arr.length;

    for (let i = 0; len > i; i++ ) {
      if (arr[i].trim() === '') return alert('모든 항목을 입력해주세요');
    }
    
    if (!agreement1 || !agreement2) return alert('이용약관에 동의해야 회원가입 할 수 있습니다.')

    const params = { ...state };

    delete params.re_password;

    try {
      await axios.post('/auth/signup', params);
      history.push('/login?signup=success');
    } catch(e) {
      console.log(e)
      alert(e.response.data.message)
    }
  }
  
  return (
    <Style>
      <h2>회원가입</h2>
      <h3>개인정보</h3>
      <div className="forms">
        <Label label="이메일">
          <Input name="email" value={state.email} placeholder="abc@gmail.com" type="email" onChange={onChange}/>
        </Label>
        <Label label="비밀번호">
          <Input name="password" value={state.password} placeholder="6 ~ 16자리 비밀번호" type="password" onChange={onChange}/>
        </Label>
        <Label label="비밀번호 확인">
          <Input name="re_password" value={state.re_password} placeholder="6 ~ 16자리 비밀번호 재입력" type="password" onChange={onChange}/>
        </Label>
        <Label label="이름">
          <Input name="name" value={state.name} placeholder="실명을 입력해주세요" type="text" onChange={onChange}/>
        </Label>
        <Label label="주민등록번호">
          <div className="social-number">
            <Input name="social_number1" value={state.social_number1} placeholder="950101" type="text" onChange={onChange}/>
            <span>-</span>
            <Input name="social_number2" value={state.social_number2} placeholder="0000000" type="text" onChange={onChange}/>
          </div>
        </Label>
        <Label label="휴대폰 번호">
          <Input name="phone" value={state.phone} placeholder="- 를 제외하고 입력해주세요" type="text" onChange={onChange}/>
        </Label>
        <Label label="주소">
          <div className="postal-code">
            <Input name="post_code" value={state.post_code} placeholder="우편번호" type="text" onChange={onChange}/>
            <Button size="small" onClick={showAddressPopup}>주소 검색</Button>
          </div>
        </Label>
        <Label empty>
          <Input name="address1" value={state.address1} placeholder="기본주소" type="text" onChange={onChange}/>
        </Label>
        <Label empty>
          <Input name="address2" value={state.address2} placeholder="상세주소" type="text" onChange={onChange}/>
        </Label>
      </div>
      <h3>급여계좌 정보</h3>
      <div className="forms">
        <Label label="은행명">
          <Input name="bank_name" value={state.bank_name} placeholder="은행명을 입력해주세요" type="text" onChange={onChange}/>
        </Label>
        <Label label="계좌번호">
          <Input name="bank_account" value={state.bank_account} placeholder="- 를 제외하고 입력해주세요" type="text" onChange={onChange}/>
        </Label>
        <Label label="예금주">
          <Input name="bank_username" value={state.bank_username} placeholder="예금주를 정확히 입력해주세요" type="text" onChange={onChange}/>
        </Label>
      </div>
      <div className="agreements">
        <div className="item">
          <CheckInput name="agreement1" checked={agreement1} onChange={checkHandler}/>
          <span><Link to="/agreements/signup-agreement" target="_blank" rel="noreferrer">회원가입약관</Link>의 내용에 동의합니다</span>
        </div>
        <div className="item">
          <CheckInput name="agreement2" checked={agreement2} onChange={checkHandler}/>
          <span><Link to="/agreements/privacy-policy" target="_blank" rel="noreferrer">개인정보 처리방침 안내</Link>의 내용에 동의합니다</span>
        </div>
      </div>
      <div className="action">
        <Button onClick={submit}>회원가입</Button>
      </div>

      { addressPopupState && <FindAddress onComplete={addressHandler}/> }

    </Style>
  )
}

export default observer(Signup)
