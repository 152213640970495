/* eslint-disable eqeqeq */
import { Button } from "comp/Button";
import config from "config";
import xlsx from "json-as-xlsx";

export const getToken = () => {
  return getCookie(config.TOKEN_KEY, document.cookie);
};

export const setToken = (token) => {
  return setCookie(config.TOKEN_KEY, token, 30);
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname, cookie) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const destroyToken = () => {
  setCookie(config.TOKEN_KEY, "", 0);
};

export const authReqeust = async (callback, ...arg) => {
  try {
    const token = getToken();
    if (token) {
      const result = await callback(...arg, {
        headers: {
          access_token: token,
        },
      });
      return result;
    }
  } catch (e) {
    throw e;
  }
};

export function buildObjQuery(queries) {
  const params = new URLSearchParams();
  Object.keys(queries).forEach((key) => {
    const value = queries[key];
    if (value === null) return;
    params.append(key, value.toString());
  });
  if (params.toString().trim() === "") return "";
  return `?${params.toString()}`;
}

export const ExportCsv = ({ headers, source }) => {
  const downloadCSV = () => {
    const columns = headers.reduce((acc, key) => {
      acc.push({
        label: key,
        value: key,
      });
      return acc;
    }, []);

    const data = [
      {
        sheet: "Users",
        columns: columns,
        content: source,
      },
    ];

    const settings = {
      fileName: "users",
    };

    xlsx(data, settings);
  };

  return <Button onClick={downloadCSV}>엑셀 다운로드</Button>;
};
