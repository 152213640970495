import theme from 'styles/theme'
import styled from 'styled-components'
import DaumPostcode from 'react-daum-postcode';

const Style = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${theme.nd(0.4)};
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    width: 400px;
    height: 700px;
  }
`

const FindAddress = ({ onComplete }) => {
  return (
    <Style>
      <div className="wrapper">
        <DaumPostcode onComplete={onComplete} height="100%"/>
      </div>
    </Style>
  )
}

export default FindAddress
